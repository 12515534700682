import { ALLOWED_IMAGE_EXT } from "@constants/index";
import { MESSAGES } from "@constants/messages";
import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { Button, ImageAvatar } from "ui-2";
import {
  hasAllowedExt,
  hasAllowedSize,
  hasDoubleExtInFileName,
  hasMultiPeriodInFileName,
  useApi,
} from "utils";
import { PhotoCropper } from "../../../components/Cropper";
import { useToastConfig } from "../../../components/toast";
import GTMACTIONS from "../../../config/gtmActions";
import { ADD_EDIT_PET } from "../../../constants/addEditPet";
import { API_URLS } from "../../../constants/apiUrls";
import { Auth, useAuth } from "../../../hooks/useAuth";
import { AddIcon } from "../../../icons/AddIcon";
import { datalayerPush } from "../../../lib/gtm";
import { Loader } from "ui-2";

export function ProfilePicture() {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const [isPhotoCropperOpen, setIsPhotoCropperOpen] = useState(false);
  const [image, setImage] = useState<File>();
  const [profilePic, setProfilePic] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState(false);

  const auth: Auth = useAuth();
  const abortControllerRef = useRef<AbortController | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);
  const { exec: updateMyProfileData } = useApi((data) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .post(
        `${process.env.REACT_APP_PROFILE_API_URL}${API_URLS.PARENT_PROFILE}`,
        data,
        {
          signal: abortController.signal,
        }
      )
      .then((apiResponse) => {
        if (
          apiResponse.status === 200 &&
          apiResponse.data.serviceStatus.code === 200
        ) {
          // Update message here after updating profile image
          showToast({
            title: "Added",
            description: `${t(MESSAGES.PROFILE_PIC_UPDATE_MESSAGE)}`,
            type: "success",
          });
        } else {
          // show error message
          showToast({
            title: "Error",
            description: `${t("common.updateFailed")}`,
            type: "error",
          });
        }
      });
  });

  function handleImageUpload(file: File) {
    setImage(file);
    setIsPhotoCropperOpen(true);
  }

  // Triggers the input's click event
  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // Captures the uploaded file after the inout's click event during onChange
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const MAX_SIZE = process.env.REACT_APP_PROFILE_PIC_SIZE_IN_MB as string;

    if (files) {
      const selectedFile = files[0];
      const hasMultiPeriod = hasMultiPeriodInFileName(selectedFile.name);
      const isAllowedExt = hasAllowedExt(ALLOWED_IMAGE_EXT, selectedFile.type);
      const isAllowedSize = hasAllowedSize(+MAX_SIZE, selectedFile.size);
      const hasDoubleExt = hasDoubleExtInFileName(selectedFile.name);

      if (!hasMultiPeriod && isAllowedExt && isAllowedSize && !hasDoubleExt) {
        // adding GA here on successful pic upload on Dashboard
        datalayerPush(GTMACTIONS.EDITPIC);

        handleImageUpload(selectedFile);
      } else {
        showToast({
          title: ADD_EDIT_PET.ADDED,
          description: !isAllowedExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : hasMultiPeriod
            ? `${t(MESSAGES.PROFILE_PIC_MULTI_PERIOD_ERROR)}`
            : hasDoubleExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : `${t(MESSAGES.PROFILE_PIC_SIZE_ERROR)}`,
          type: "error",
        });
      }
      e.target.value = "";
    }
  };

  function handleCroppedImageUpload(croppedImage: string, croppedArea?: Area) {
    setIsLoading(true);
    if (croppedImage && croppedArea) {
      const formData = new FormData();
      formData.append("image", image as Blob);
      formData.append(
        "rendition",
        JSON.stringify({
          width: croppedArea.width,
          height: croppedArea.height,
          left: croppedArea.x,
          top: croppedArea.y,
        })
      );
      formData.append("data", JSON.stringify({ profile: {} }));
      
      updateMyProfileData(formData).then(() => {
        setProfilePic(croppedImage);
        auth?.setUser?.((prev) => ({
          ...prev,
          profile: { ...prev.profile, photoURL: croppedImage },
        }));
        setIsLoading(false);
      });
    }
    setIsPhotoCropperOpen(false);
  }

  const initials =
    auth?.user?.profile?.firstName?.charAt(0) ||
    "" + auth?.user?.profile?.lastName?.charAt(0) || "";

  return (
    <>
      {isLoading ?
        <Loader/> :
        <div className="flex items-center gap-2">
        <ImageAvatar src={auth?.user?.profile.photoURL} initials={initials} />
        <Button
          prefixIcon={<AddIcon />}
          variant="blueText"
          onClick={handleInputClick}
        >
          {profilePic ? t("common.editImage") : t("common.addImage")}
        </Button>
        <input
          ref={inputRef}
          id="profile-pic-upload"
          aria-label="profile pic upload"
          type="file"
          accept={ALLOWED_IMAGE_EXT.join(",")}
          onChange={handleFileChange}
          data-testid="profile-pic-upload"
          hidden
        />
        <PhotoCropper
          open={isPhotoCropperOpen}
          setOpen={setIsPhotoCropperOpen}
          image={image ? URL.createObjectURL(image as Blob) : ""}
          aspect={1}
          cropShape="round"
          title={t("common.cropImage")}
          setCroppedImage={handleCroppedImageUpload}
        />
        </div>
      }
    </>
  );
}

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ManagePassKeyInfo } from "../../../components/ManagePassKeyInfo";
import * as Modal from "../../../components/Modal";
import { ACCOUNT_SETTINGS, ROUTES } from "../../../constants";
import useRequireAuth from "../../../hooks/useRequireAuth";
import { useToastConfig } from "../../../components/toast";

export function ManagePassKeyModal() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const auth = useRequireAuth();
  const { showToast } = useToastConfig();

  const handleCloseModal = useCallback(function handleCloseModal() {
    auth.fetchMyProfileData && auth.fetchMyProfileData();
    showToast({
      description: t(ACCOUNT_SETTINGS.CHANGES_SAVED),
      type: "success",
    });
    navigate(ROUTES.ROOT + ROUTES.ACCOUNT_SETTINGS);
  }, []);

  return (
    <Modal.Root
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          handleCloseModal();
        }
      }}
    >
      <Modal.SideModal title={t(ACCOUNT_SETTINGS.MANAGE_PASSKEY)}>
        <ManagePassKeyInfo />
      </Modal.SideModal>
    </Modal.Root>
  );
}
